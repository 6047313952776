import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import ContractMixin from "@/core/lib/contract/contract.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import EntityDialog from "@/view/pages/partials/Select-Entity.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
export default {
  mixins: [CommonMixin, ListingMixin, ContractMixin],
  name: "contract-list",
  data: function data() {
    return {
      exportLoading: false,
      dates: [],
      pageModule: "contract-listing",
      routeAPI: "contract",
      routeName: "contract",
      routeDetailName: "contract.detail",
      status: "all",
      statusList: [],
      moreActions: [
      /*{
        title: "Export Contract(s)",
        action: "export_items",
        divider: false,
        icon: "mdi-database-export",
      },*/

      /*{
        title: "Export Current View",
        action: "export_current_view",
        divider: true,
        icon: "mdi-database-export",
      },*/
      {
        title: "Refresh List",
        action: "refresh_list",
        divider: true,
        icon: "mdi-refresh"
      }],
      bulkActions: []
    };
  },
  components: {
    PageTips: PageTips,
    draggable: draggable,
    EntityDialog: EntityDialog,
    CustomStatus: CustomStatus,
    PageHeaderCount: PageHeaderCount,
    Barcode: Barcode,
    TableActivity: TableActivity,
    ListingTemplate: ListingTemplate,
    ListingFooter: ListingFooter,
    ListingTable: ListingTable,
    ListingHeader: ListingHeader,
    ListingSearchCriteria: ListingSearchCriteria,
    Dialog: Dialog,
    CustomerDialog: CustomerDialog,
    CustomerPropertyDialog: CustomerPropertyDialog,
    CustomerPersonDialog: CustomerPersonDialog
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      }
    }
  },
  methods: {
    selectCustomerDialog: function selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog: function selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog: function selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    resetAll: function resetAll() {
      this.entity = 0;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog: function closeDialog() {
      this.entityDialog = false;
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectEntity: function selectEntity(param) {
      var _this2 = this;

      this.entity = param;
      this.closeDialog();
      this.$nextTick(function () {
        _this2.selectCustomerDialog();
      });
    },
    selectCustomer: function selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkContract();

      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson: function selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkContract();

      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty: function selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkContract();
    },
    checkContract: function checkContract() {
      var _this = this;

      if (_this.customer > 0 && _this.customerProperty > 0) {
        var _this$customer, _this$customerPropert;

        _this.$router.push(_this.getDefaultRoute("contract.create", {
          query: {
            customer: (_this$customer = _this.customer) !== null && _this$customer !== void 0 ? _this$customer : undefined,
            property: (_this$customerPropert = _this.customerProperty) !== null && _this$customerPropert !== void 0 ? _this$customerPropert : undefined
          }
        }));
      }
    },
    moreAction: function moreAction(action) {
      var _this = this;

      switch (action) {
        case "import_items":
          break;

        case "export_items":
          _this.bulkContractExport();

          break;

        case "export_current_view":
          _this.exportCurrentView("contract");

          break;

        case "refresh_list":
          _this.getRows();

          break;
      }
    },
    updateBulkAction: function updateBulkAction(param) {
      var _this = this;

      switch (param) {
        case "draft":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 1
          });

          break;

        case "accepted":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 2
          });

          break;

        case "rejected":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 3
          });

          break;

        case "pending":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 4
          });

          break;

        case "sent":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 5
          });

          break;

        case "cancelled":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 6
          });

          break;

        case "closed":
          _this.bulkContractUpdate({
            contracts: _this.selectedRows,
            status: 7
          });

          break;
      }
    },
    bulkContractUpdate: function bulkContractUpdate(requestObject) {
      var _this = this;

      _this.$store.dispatch(PATCH, {
        url: "contract",
        data: requestObject
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.selectedRows = [];

        _this.getRows();
      });
    },
    bulkContractExport: function bulkContractExport() {
      var _this = this;

      var fileName = new Date();
      _this.exportLoading = true;

      _this.$store.dispatch(DOWNLOAD, {
        url: "contracts/export"
      }).then(function (_ref) {
        var data = _ref.data;
        saveAs(data, "contracts-export-" + fileName.getTime() + ".xlsx");
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.exportLoading = false;
      });
    }
  },
  mounted: function mounted() {
    this.resetAll();
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Contract"
    }]);
  },
  beforeMount: function beforeMount() {
    var _this = this;

    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [{
      headerName: "",
      field: "id",
      sort: null,
      visible: true,
      fixed: true,
      sortable: false,
      checkbox: true,
      order: 0,
      width: "50px"
    }, {
      headerName: "Contract #",
      field: "barcode",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 1,
      width: "200px"
    }, {
      headerName: "Job Title / Company Address",
      field: "job_title",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 1,
      width: "300px"
    }, {
      headerName: "Basic Details",
      field: "basic_detail",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 1,
      width: "300px"
    }, {
      headerName: "Dates",
      field: "date",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 1,
      width: "200px"
    }, {
      headerName: "Customer Info",
      field: "company_info",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 3,
      width: "300px"
    }, {
      headerName: "Status",
      field: "status",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 3,
      width: "200px"
    }, {
      headerName: "Amount",
      field: "amount",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 3,
      width: "200px"
    }, {
      headerName: "Created Time",
      child: "created_at",
      field: "added_at",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 9,
      width: "170px"
    }, {
      headerName: "Last Modified Time",
      child: "modified_at",
      field: "updated_at",
      sort: null,
      visible: false,
      fixed: false,
      sortable: false,
      order: 10,
      width: "170px"
    }];

    var defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true
    });

    _this.defaultColShow = defaultColDefs.map(function (col) {
      return col.field;
    });
    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize = window.localStorage.getItem(_this.pageModule) || 10;
  }
};